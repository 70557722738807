<!--

      This view displays the list of recent orders. It is reachable from within
      the profile tab.

 -->

<template>
  <Page title='Warenkorb' hideback="true" :noPaddingTop="didyouorder" :key="updateOrder" ref="cart">
    <template v-slot:header>
      <div class="list-item underline" @click="deleteWholeCart()">
        Alles löschen
      </div>
    </template>
    
    <div :class="['didyouorder', { 'didyouorder-with-shopping-list' : shoppingList }]" v-if="didyouorder" @click="$store.dispatch('openModal', {name: 'didyouorder'})">
      <InfoBox icon="foodableHeart">Hast du diesen Warenkorb schon bei REWE bestellt? <div class="btn-link">Jetzt zum Bestätigen</div></InfoBox>
      <br>
    </div>

    <!-- Tab Bar to select between Products and Recipes -->
    <TabSwiper :tabs="tabs" :tab="tab" center="true" @clicked="t => tab = t" class="recipe-product-tabs" />

    <!-- Products content -->
    <div v-if="this.$store.state.neworder" v-show="tab === 'Produkte'">
      <div class="availability-notification" v-if="$store.getters.countUnavailableProductsInNeworder > 0">
        <div>
          <Icon name="alertCircleOutline" class="availability-icon" />
        </div>
        <div class="secondary-text text-white">
          {{ $store.getters.countUnavailableProductsInNeworder }}
          <span v-if="$store.getters.countUnavailableProductsInNeworder > 1">Produkte sind nicht mehr verfügbar. Bitte wähle Alternativen aus.</span>
          <span v-if="$store.getters.countUnavailableProductsInNeworder === 1">Produkt ist nicht mehr verfügbar. Bitte wähle eine Alternative aus.</span>
        </div>
      </div>
      <!-- Card to show if no products in cart -->
      <NewCard v-if="cartContainsZeroProducts" goTo="products" icon="bagHandleOutline">
        Du hast noch keine Produkte!<br>Füge Rezepte oder Produkte hinzu<br>um hier etwas zu sehen.
      </NewCard>
      <PartialList v-for="cat in shoppingList" :key="cat.name" :items="cat" v-show="cat.visible" />
      <div class="white-box-small main-text">
        <div class="total-bar">
          <div class="main-text text-left">
            foodable Service
          </div>
          <div class="main-text text-right">
            kostenlos
          </div>
        </div>
        <div class="total-bar">
          <div>Gesamt</div>
          <Price :price="$store.state.neworder.price" />
        </div>
      </div>
      <div 
        class="text-section secondary-text-gray text-left"
          v-if="this.$store.state.neworder.price < 50 && this.$store.state.profile && this.$store.state.profile.preferred_store_branch.store_name === 'Rewe'"
      >
        Du hast den Mindestbestellwert von 50€ noch nicht erreicht, 
        kannst aber über den REWE Abholservice bestellen.
      </div>
    </div>

    <!-- Recipes content -->
    <!-- Card to show if no recipes in cart -->
    <NewCard v-if="cartContainsZeroRecipes" goTo="recipes" icon="bagHandleOutline">
      Du hast noch keine Rezepte!<br>Klicke hier um Rezepte hinzuzufügen.
    </NewCard>
    <div class="info-tiles" v-show="tab === 'Rezepte'" v-if="days && !cartContainsZeroRecipes">
      <div class="info-tile-4">
        <div class="btn-link">{{ days.length }}</div>
        Tage
      </div>
      <div class="info-tile-4">
        <div class="btn-link">
          {{ people }}
        </div>
        Personen
      </div>
      <div class="info-tile-4">
        <div class="btn-link">
          <Price :price="pricePerServing" />
        </div>
        / Portion
      </div>
      <div class="info-tile-4" @click="openLeftovers()">
        <div class="btn-link">{{ leftoversConsumption }}%</div>
        <div class="tertiary-text">Verbrauch</div>
      </div>
    </div>
    <!-- The recipes -->
    <div class="confetti-container" v-if="$store.state.showConfettiAfterMealPlanGeneration">
      <div v-for="i in 20" :key="i" class="confetti"></div>
    </div>
    <div>
      <RecipeCard
        v-show="tab === 'Rezepte'"
        v-for="day in this.days"
        :key="day.id"
        :rplan="day.planrecipes[0]"
        type="planning"
        ref="recipecard"
        :showAvailability="true"
      />
    </div>
    <div class="btn-secondary add-button" data-cy="addrecipe" @click="$store.dispatch('goTo', 'recipes')" v-if="this.tab === 'Rezepte' && !cartContainsZeroRecipes">Rezept hinzufügen</div>
    <AddMealsToPlan v-if="this.tab === 'Rezepte'" />

    <!-- Skeleton cards while recipes are loading -->
    <ion-skeleton-text animated class="skeleton-card" v-if="showSkeletonCards && this.tab === 'Rezepte'"></ion-skeleton-text>
    <ion-skeleton-text animated class="skeleton-card" v-if="showSkeletonCards && this.tab === 'Rezepte'"></ion-skeleton-text>
    <ion-skeleton-text animated class="skeleton-card" v-if="showSkeletonCards && this.tab === 'Rezepte'"></ion-skeleton-text>
    
    <!-- Checkout button at bottom of page -->
    <template v-slot:global>
      <div class="next-button">
        <div class="btn-primary standalone" @click="goToCheckout()">
          <div class="button-inside">
            Zum Vorratscheck · <Price :key="$store.state.neworder.price" :price="$store.state.neworder.price" v-if="$store.state.neworder" :estimated="true" />
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import InfoBox from '@/components/info/InfoBox.vue'
import PartialList from '@/components/shopping/PartialList.vue'
import TabSwiper from '@/components/TabSwiper.vue'
import Price from '@/components/Price.vue'
import RecipeCard from '@/components/cards/RecipeCard.vue'
import AddMealsToPlan from '@/components/AddMealsToPlan.vue'
import NewCard from '@/components/cards/NewCard.vue'
import { IonSkeletonText } from '@ionic/vue'

import * as Sentry from '@sentry/browser'

export default {
  name: 'shopping',
  props: ['initialTab'],
  components: {
    Page,
    PartialList,
    TabSwiper,
    InfoBox,
    Price,
    RecipeCard,
    NewCard,
    AddMealsToPlan,
    IonSkeletonText
  },
  data() {
    return {
      checkoutURL: null,
      shoppingList: null,
      showSkeletonCards: null,
      tabs: [
        'Produkte',
        'Rezepte'
      ],
      tab: 'Produkte',
      generatingMessages: [
        "Suche Rezepte, die dir gefallen könnten!",
        "Reduziere deinen Lebensmittelabfall!",
        "Optimiere deinen Preis!",
        "Optimiere deinen Verbrauch von verderblichen Lebensmitteln!",
        "Suche Rezepte für deine Haushaltsgröße!",
        "Erstelle den Warenkorb für deine Rezepte!",
        "Suche Rezepte, die vollständig verfügbar sind!",
        "Suche Rezepte für weitere Empfehlungen!",
        "Der beste Ansatz zur Vermeidung von Lebensmittelverschwendung? Planen!",
        "Schließe Zutaten aus, die du nicht magst!"
      ],
    }
  },
  computed: {
    cartContainsZeroRecipes () {
      const showTheCard = this.tab === 'Rezepte'
                       && this.$store.state.newplan
                       && this.$store.state.newplan.single_days.length <= 0
      return showTheCard
    },

    cartContainsZeroProducts () {
      const showTheCard = this.tab === 'Produkte'
                       && this.$store.state.neworder
                       && this.$store.state.neworder.price <= 0
      return showTheCard
    },

    didyouorder () {
      if (this.neworder && this.neworder.id === this.$store.state.neworderWithLastStepRewe) {
        return true
      }
    },

    updateOrder () {
      if (this.neworder && this.neworder.id) {
        return this.neworder.id
      }
    },

    loadingMessage () {
      if (this.$store.state.generatingPlan) {
        return this.generatingMessages[Math.floor(Math.random() * this.generatingMessages.length)]
      }
    },

    days () {
      const days = this.$store.getters.days
      // Track recipe impressions for the menu recipes
      if (days) {
        Sentry.configureScope(function (scope) {
          scope.setExtra('days', JSON.stringify(days))
        })
        const recipeIds = days.map(day => {
          if (day && day.planrecipes[0] && day.planrecipes[0].recipe && day.planrecipes[0].recipe.id) {
            return day.planrecipes[0].recipe.id
          }
        })
        const impressions = recipeIds.map(recipeId => (
          { recipe: recipeId, flag: this.$store.state.recipeImpressionFlags.SEEN_IN_MENU }
        ))
        this.$store.dispatch('recordRecipeImpression', impressions)
      }
      return days
    },

    neworder () {
      if (this.$store.state.neworder) {
        return this.$store.state.neworder
      }
    },

    countServings () {
      if (this.$store.state.newplan && this.$store.state.newplan.single_days) {
        let countServings = 0
        this.$store.state.newplan.single_days.forEach(day => {
          if (day && day.planrecipes[0] && day.planrecipes[0].hasOwnProperty('serving_size')) {
            countServings += day.planrecipes[0].serving_size
          }
        })
        return countServings
      }
    },

    people () {
      if (this.countServings && this.days.length) {
        return Math.round(this.countServings * 10 / this.days.length) / 10
      } else {
        return 0
      }
    },

    pricePerServing () {
      if (this.$store.state.neworder && this.$store.state.neworder.price && this.countServings) {
        return this.$store.state.neworder.price / this.countServings
      } else {
        return 0
      }
    },

    leftoversConsumption () {
      if (this.$store.state.neworderLeftovers && this.$store.state.neworderLeftovers.hasOwnProperty('consumption_ratio')) {
        const consumption = Math.round(this.$store.state.neworderLeftovers.consumption_ratio * 100)
        if (consumption > 100) {
          return 100
        } else {
          return consumption
        }
      }
    }
  },
  watch: {
    neworder () {
      this.buildShoppingList()
    }
  },
  methods: {
    deleteWholeCart () {
      const plan = this.$store.state.newplan
      // Update the savedPlans array
      if (plan.saved) {
        this.$store.state.savedPlans = this.$store.state.savedPlans.filter(p => p.id !== plan.id)
      }
      this.$store.dispatch('save', { key: 'savedPlans', value: this.$store.state.savedPlans })
      
      // Remove the plan and its order completely
      this.$store.dispatch('removeOpenOrderAndPlan', plan.id)
    },
    goToCheckout () {
      if (this.$store.state.neworder && this.$store.state.neworder.price > 0) {
        this.$store.dispatch('goTo', 'checkout')
      } else if (this.$store.state.neworder && this.$store.state.neworder.price <= 0) {
        this.$store.dispatch('showNotification', { message: 'Huch, dein Warenkorb ist noch leer. Bitte füge Produkte oder Rezepte hinzu.', type: 'Error'})
      }
    },
    openLeftovers () {
      if (this.$store.state.neworderLeftovers) {
        this.$store.dispatch('openModal', { name: 'leftovers', data: this.$store.state.neworderLeftovers })
      }
    },
    async buildShoppingList (orderproducts) {
      if (orderproducts) {
        let categories = []

        // Build the shopping list based on the order products and
        // their respective categories.
        orderproducts.forEach(op => {
          if (op.quantity === 0 ) { 
            return
          }
          const cat = op.product_category
          const foundCategory = categories.find(c => c.name === cat)
          if (!foundCategory) {
            categories.push({
              name: cat,
              products: [op],
              hasUnavailable: false
            })
          } else {
            foundCategory.products.push(op)
          }
        })
        
        // Ensure the shopping list contains the original order products
        // This is required to show the amount that is needed for each
        // recipe in the product alternatives page (Product.vue).
        await Promise.all(categories.map(async cat => {
          cat.products = await this.$store.dispatch('mergeSimilarOrderProducts', cat.products)
 
          if (!this.$store.state.neworderUnavailableProducts) {
            await this.$store.dispatch('getNewOrderUnavailableProducts', this.$store.state.neworder)
          }
          // we need this check, because getNewOrderUnavailableProducts could return null
          cat.products.forEach(product => {
            if (this.$store.state.neworderUnavailableProducts) { 
              product['unavailable'] = this.$store.state.neworderUnavailableProducts.some(p => p.product === product.product_id)
              if (product['unavailable']) {
                cat['hasUnavailable'] = true
              }
            }
            product.visible = product.quantity > 0 || product.is_durable
          })
          cat['visible'] = cat.products.some(p => p.visible)
        }))
        
        // Save the final shopping list after sorting it by name of categories.
        this.shoppingList = categories.sort((a, b) => a.name.localeCompare(b.name))
      }
    },
  },
  mounted () {
    this.$store.dispatch('save', { key: 'tab', value: 'cart' })
    if (!this.$store.state.newplan) {
      this.$store.dispatch('autoSetNewplanAndNeworder')
    }
    if (this.initialTab) {
      this.tab = this.initialTab
    }
  },
  updated () {
    if (this.$store.state.neworder && this.$store.state.neworder.orderproducts) {
      this.buildShoppingList(this.$store.state.neworder.orderproducts)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.add-button {
  margin-top: 20px;
}

.recipe-product-tabs {
  position: sticky;
  top: 0;
  z-index: 10;
  background: $app-bg;
  padding: 5px 0;
  margin-top: -20px;
}

.availability-notification {
  margin: 20px;
  background: $col-red;
  color: white;
  padding: 10px 10px;
  font-size: .9rem;
  border-radius: $border-radius;
  text-align: left;
  display: flex;
  align-items: center;
}

.availability-icon {
  height: 32px;
  width: 32px;
  --stroke: white;
  fill: white;
  margin-right: 10px;
}

.next-button {
  @extend .next-buttons;
  margin-bottom: 30px;
}
</style>